import axios from "axios";
import { FETCH_FEATUREDCARDS_FAILURE, FETCH_FEATUREDCARDS_REQUEST, FETCH_FEATUREDCARDS_SUCCESS } from "./featureCardsTypes";
import { REACT_APP_baseUrl_V2 } from 'utils/app-constants';

export const fetchFeatureCardsRequest = () => {
    return {
        type:FETCH_FEATUREDCARDS_REQUEST,
    }
}

export const fetchFeatureCardsSuccess = data => {
    return {
        type:FETCH_FEATUREDCARDS_SUCCESS,
        payload:data
    }
}

export const fetchFeatureCardsFailure = error =>{
    return {
        type:FETCH_FEATUREDCARDS_FAILURE,
        payload:error
    }
}

export const fetchFeatureCards = (params) => {
    return (dispatch)=>{
        dispatch({type:FETCH_FEATUREDCARDS_REQUEST});
        axios.get(`${REACT_APP_baseUrl_V2}/brands/get_brands`,{
                params
            })
            .then(response  => {

                if(response.data?.code === 200){
                    const featureCards = response.data?.data?.brands;
                dispatch(fetchFeatureCardsSuccess(featureCards))
                }
                else{
                    dispatch(fetchFeatureCardsFailure(response.data?.message))
                }
              
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchFeatureCardsFailure("Something went wrong!! Please try again!!"))
            })
    }
}