import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import Breadcrumbs from "../shared/Breadcrumbs";
import arrowImg from "../../assests/img/TravelShop/sidearrow.png";
import { ReactComponent as Calendar } from "../../assests/img/TravelShop/calendar.svg";
import { ReactComponent as User } from "../../assests/img/TravelShop/user.svg";
import { ReactComponent as Economy } from "../../assests/img/TravelShop/economy.svg";
import { ReactComponent as EditButton } from "../../assests/img/TravelShop/editButton.svg";
import { ReactComponent as PlaneTicket } from "../../assests/img/TravelShop/plane-ticket-large.svg";
import { ReactComponent as ArrowRight } from "../../assests/img/TravelShop/arrow-right.svg";
import arrowup from "../../assests/img/TravelShop/arrow-up.svg";
import Emirates from "../../assests/img/TravelShop/emirates.svg";
import Qatar from "../../assests/img/TravelShop/qatar.png";
import Eagle from "../../assests/img/TravelShop/eagle.png";
import British from "../../assests/img/TravelShop/british.svg";
import { ReactComponent as PlaneUp } from "../../assests/img/TravelShop/plane-up.svg";
import { ReactComponent as PlaneDown } from "../../assests/img/TravelShop/plane-down.svg";
import { ReactComponent as PlaneDownRight } from "../../assests/img/TravelShop/plane-down-right.svg";
import { ReactComponent as Clock } from "../../assests/img/TravelShop/clock.svg";
import { ReactComponent as Seat } from "../../assests/img/TravelShop/seat.svg";
import { ReactComponent as Boeing } from "../../assests/img/TravelShop/boeing.svg";
import { ReactComponent as Baggage } from "../../assests/img/TravelShop/baggage.svg";
import { ReactComponent as Meal } from "../../assests/img/TravelShop/meal.svg";
import OutBoundFlight from "./OutBoundFlight";
import TravelDetailTopBar from "./TravelDetailTopBar";
import StepFlight from "./StepFlight";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import Loader from "./Loader";
import axios from "axios";
import { setSearchOrder, setSelectedFlightDetails } from "../../Redux/travelShop/TravelAction";
import FailMessageModal from "../shared/Modals/FailMessageModal";
import { fetchAuthTokenSuccess } from "../../Redux/auth/authActions";
import LoginModal from "../shared/Modals/LoginModal";
import { REACT_APP_baseUrl } from "utils/app-constants";
import { numberWithCommas } from '../../utils/pointsConversion'

class FlightsDetails extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loading: false,
      toggle: {
        first: true,
        second: true,
        third: true,
        fourth: true,
        flightDetails: { outBoundFlight: [], inBoundFlight: [] },
      },
    };
  }
  toggleHandler = (type) => {
    this.setState({
      toggle: { ...this.state.toggle, [type]: !this.state.toggle[type] },
    });
  };

  componentDidMount = () => {
    this.handleFlightData()
    this.myRef.current?.scrollIntoView({behavior: "smooth"})
  }

  componentWillReceiveProps = () => {
    this.handleFlightData()
  }

  handleFlightData = () => {
    const { searchResults, selectedFlightDetail } = this.props
    if (!selectedFlightDetail?.search) {
      this.props.history.push('/flights')
      return
    }
    const flightDetails = selectedFlightDetail?.search?.data?.segments?.reduce((result, segment) => {
      if (segment.segment_indicator === 1) {
        result["outBoundFlight"].push(segment)
      } else {
        result["inBoundFlight"].push(segment)
      }
      return result
    }, { outBoundFlight: [], inBoundFlight: [] })
    flightDetails['fare_local'] = selectedFlightDetail?.search?.data?.fare_local
    this.setState({ flightDetails })
  }

  fetchSearch = (payload, segments, order) => {
    const token = localStorage.getItem("userToken");
    payload['search']['currency'] = this.props.currancyCode
    this.setState({ loading: true })
    axios.post(`${REACT_APP_baseUrl}/travel_shops/fetch_search?order=${order}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'x-customer-uuid' : localStorage.getItem("customer_uuid")
        },
      }
    ).then((response) => {
      if (response.data.status === 'in_progress') {
        setTimeout(() => {
          this.fetchSearch(payload, segments, order)
        }, 1000);
      } else {
        const data = { ...response.data.data }
        localStorage.setItem('search_id', response.data.data.search_id)
        if (segments) data.segments = segments
        this.props.setSearchResults(data)
        this.setState({ loading: false })
      }
    }).catch(err => {
      console.log(`err`, err)
      this.setState({ loading: false })
    })
  }

  changeOrder = (order) => {
    if (this.props.searchOrder !== order.value) {
      const search_id = localStorage.getItem('search_id')
      this.props.setSearchOrder(order.value)
      this.fetchSearch({
        search: {
          search_id,
          "query_params": {
            order: order.value
          }
        }
      }, false, order.value)
    }
  }

  handleViewDetail = (result_id) => {
    const token = localStorage.getItem("userToken");
    const search_id = localStorage.getItem("search_id");
    const payload = {
      search: {
        search_id: search_id,
        result_id: result_id,
        update_information: false
      }
    }
    this.setState({ loading: true })
    axios.post(`${REACT_APP_baseUrl}/travel_shops/post_search_by_result_id`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'x-customer-uuid' : localStorage.getItem("customer_uuid")
        },
      }
    )
      .then((response) => {
        axios.get(`${REACT_APP_baseUrl}/travel_shops/get_search_detail?search_id=${search_id}&result_id=${result_id}&currency=${this.props.currancyCode}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'x-customer-uuid' : localStorage.getItem("customer_uuid")
            },
          }
        ).then((response) => {
          console.log(`response`, response)
          if (response.data?.data) {
            localStorage.setItem("result_id", result_id);
            this.props.setSelectedFlightDetails(response.data?.data)
            this.setState({ loading: false })
            // window.scroll({
            //   top: 0,
            //   left: 0,
            //   behavior: 'smooth'
            // });
            this.props.history.push('/contacts')
          } else {
            if (response.data?.code === 401) {
              this.props.fetchAuthTokenSuccess('')
              this.setState({ loading: false })
            } else {
              this.setState({ loading: false, searchError: response.data?.message || 'Something went wrong, please try again later!' })
            }
          }
          // this.setState({ countries: filteredAirports, loading: false })
        }).catch(err => {
          console.log(`err`, err)
          this.setState({ loading: false, searchError: err?.response?.data?.message || 'Something went wrong, please try again later!' })
        })
      })
      .catch((err) => {
        console.log(`err`, err)
        this.setState({ loading: false, searchError: err?.response?.data?.message || 'Something went wrong, please try again later!' })
      })
  };

  DesktopView = () => {
    const breadcrumbs = [
      { name: "Home", url: "/", active: true },
      { name: "Travel Shop", url: `/flights`, active: true },
      { name: "Flight Details", url: "/flights-details", active: false },
    ];

    const flightInfo = [
      {
        icon: <EditButton />,
        item: "change",
      },
    ];

    const flightOptions = [
      {
        icon: <Clock />,
        name: "07h 35m",
      },
      {
        icon: <Seat />,
        name: "Economy",
      },
      {
        icon: <Boeing />,
        name: "Boeing 777-300ER ( EK 5 )",
      },
      {
        icon: <Baggage />,
        name: "Baggage (30KG)",
      },
      {
        icon: <Meal />,
        name: "Meal",
      },
    ];
    const OutBoundFlightList = [
      {
        flightImage: Emirates,
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '1 stop IST',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '9,910'
      },
      {
        flightImage: Qatar,
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '1 stop IST',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '7,520'
      },
      {
        flightImage: Eagle,
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '1 stop IST',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '9,490'
      },
      {
        flightImage: Qatar,
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '1 stop IST',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '7,520'
      },
      {
        flightImage: Eagle,
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '1 stop IST',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '9,490'
      },
    ]
    const { searchResults, selectedFlightDetail, point } = this.props
    const { flightDetails } = this.state

    let missingPoint = 0
    if (point && point + 1) {
      missingPoint = flightDetails?.fare_local?.price_in_points - point
    }

    const nonstopFlight = (flightData, selectedFlightDetail, inBoundFlight) => {
      if (flightData) {
        const toggleVariable = inBoundFlight ? `${selectedFlightDetail.resultID}inBoundFlight` : `${selectedFlightDetail.resultID}outBoundFlight`
        return (
          <>
            {inBoundFlight ?
              <div className="d-flex pt-4">
                <div className="bounding-icon">
                  <PlaneDown />
                </div>
                <div className="bounding">
                  <h5>{t("Inbound flight")} {moment(searchResults?.segments[1]?.departure_date).format("MMM DD")}</h5>
                  <span>{flightDetails?.inBoundFlight[0]?.origin?.city} - {flightDetails?.inBoundFlight[flightDetails?.inBoundFlight?.length - 1]?.destination?.city}</span>
                </div>
              </div> : null
            }
            <div className="flight-routes mt-3">
              <Accordion className="w-100" defaultActiveKey="1">
                <Card className="w-100 rounded-0">
                  <div className="flight-routes-info">
                    <div className="airline-name d-md-block d-none">
                      <div className="d-inline-block text-center">
                        <img loading='lazy' decoding='async' width="50" src={flightData?.airline_image} alt="" />
                        <h5 className="text-gray-100">
                          {flightData?.airline_name}
                        </h5>
                      </div>
                    </div>
                    <div className="airline-time pl-sm-4 pl-3 pl-xl-0">
                      <h6>{moment(flightData?.departure_time).format("HH:mm")}</h6>
                      <p>{flightData?.origin?.city_code}</p>
                    </div>
                    <div className="airline-direct text-center">
                      <div className="airline-direct-inner">
                        <span className="color-blue">{t("Direct")}</span>
                        <div className="direct"></div>
                        <p>{moment(flightData?.duration, 'hh:mm:ss').format('HH')}{t("h")} {moment(flightData?.duration, 'hh:mm:ss').format('mm')}{t("m")}</p>
                      </div>
                    </div>
                    <div className="airline-time pl-lg-5 pl-4">
                      <h5>{moment(flightData?.arrival_time).format("HH:mm")}</h5>
                      <p>{flightData?.destination?.city_code}</p>
                    </div>
                    <div className="airline-name d-md-none d-block">
                      <img loading='lazy' decoding='async' width="50" src={flightData?.airline_image} alt="" />
                      <h5 className="text-gray-100">
                        {flightData?.airline_name}
                      </h5>
                    </div>
                    <div className="more-info text-md-center text-right">
                      <Accordion.Toggle
                        as={Card.Header}
                        className="p-0 border-0"
                        eventKey="2"
                        onClick={() => {
                          this.toggleHandler(toggleVariable);
                        }}
                      >
                        <img loading='lazy' decoding='async'
                          src={arrowup}
                          alt="arrow"
                          className={
                            this.state.toggle[toggleVariable]
                              ? "toggleup"
                              : "toggledown"
                          }
                        />
                        {this.state.toggle[toggleVariable]
                          ? t("See less")
                          : t("See more")}
                      </Accordion.Toggle>
                    </div>
                  </div>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="border-top">
                      <div className="flight-info">
                        <div className="d-flex align-items-center flex-fill">
                          <div className="flight-timeline"></div>
                          <div className="bonding-info flex-fill">
                            <PlaneUp />
                            <div className="pl-4 flex-fill">
                              <div className="bonding-time">
                                <h5 className="mb-0 fw-normal">
                                  {moment(flightData?.departure_time).format("HH:mm")}
                                </h5>
                                <p className="mb-0">
                                  {flightData?.origin?.name} ({flightData?.origin?.city_code})
                                </p>
                              </div>
                              <div className="d-flex align-items-center my-4 pl-md-5 ml-xl-5">
                                <img loading='lazy' decoding='async'
                                  width={20}
                                  src={flightData?.airline_image}
                                  alt=""
                                />
                                <h5 className="mb-0 ml-2 text-gray-100 fw-normal">
                                  {flightData?.airline_name}
                                </h5>
                              </div>
                              <div className="bonding-time">
                                <h5 className="mb-0 fw-normal">
                                  {moment(flightData?.arrival_time).format("HH:mm")}
                                </h5>
                                <p className="mb-0">
                                  {flightData?.destination?.name} ({flightData?.destination?.city_code})
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flight-options">
                          <ul className="p-0 m-0">
                            <li>
                              <Clock />
                              <span>{moment(flightData?.duration, 'hh:mm:ss').format('HH')}h {moment(flightData?.duration, 'hh:mm:ss').format('mm')}m</span>
                            </li>
                            <li>
                              <Seat />
                              <span>{`${t(flightData?.cabin_class)}`}</span>
                            </li>
                            <li>
                              <Boeing />
                              <span>{t("Flight")} {flightData?.airline_code} {flightData?.flight_number}</span>
                            </li>
                            <li>
                              <Baggage />
                              <span>{flightData?.included_baggage}</span>
                            </li>
                            <li>
                              <Meal />
                              <span>{flightData?.meal || ''}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </>
        )
      }
      return (
        <>
          <div className="d-flex pt-4">
            <div className="bounding-icon">
              <PlaneDown />
            </div>
            <div className="bounding">
              <h5>{t("Inbound flight")} {moment(searchResults?.segments[1]?.departure_date).format("MMM DD")}</h5>
              <span>{flightDetails?.inBoundFlight[0]?.origin?.city} - {flightDetails?.inBoundFlight[flightDetails?.inBoundFlight?.length - 1]?.destination?.city}</span>
            </div>
          </div>
          <div className="flight-routes mt-3">
            <Accordion className="w-100" defaultActiveKey="1">
              <Card className="w-100 rounded-0">
                <div className="flight-routes-info">
                  <div className="airline-name">
                    <div className="d-inline-block text-center">
                      <img loading='lazy' decoding='async' src={British} alt="" />
                      <h5 className="text-gray-100">
                        British Airways
                      </h5>
                    </div>
                  </div>
                  <div className="airline-time pl-4 pl-xl-0">
                    <h6>12:10</h6>
                    <p>LHR</p>
                  </div>
                  <div className="airline-direct text-center">
                    <div className="airline-direct-inner">
                      <span className="color-blue">{t("Direct")}</span>
                      <div className="direct"></div>
                      <p>06h 55m</p>
                    </div>
                  </div>
                  <div className="airline-time pl-lg-5 pl-4">
                    <h5>07:05</h5>
                    <p>DXB</p>
                  </div>
                  <div className="more-info text-center">
                    <Accordion.Toggle
                      as={Card.Header}
                      className="p-0 border-0"
                      eventKey="2"
                      onClick={() => {
                        this.toggleHandler("third");
                      }}
                    >
                      <img loading='lazy' decoding='async'
                        src={arrowup}
                        alt="arrow"
                        className={
                          this.state.toggle?.third
                            ? "toggleup"
                            : "toggledown"
                        }
                      />
                      {this.state.toggle?.third
                        ? t("See less")
                        : t("See more")}
                    </Accordion.Toggle>
                  </div>
                </div>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="border-top">
                    <div className="flight-info">
                      <div className="d-flex align-items-center flex-fill">
                        <div className="flight-timeline"></div>
                        <div className="bonding-info flex-fill">
                          <PlaneUp />
                          <div className="pl-4 flex-fill">
                            <div className="bonding-time">
                              <h5 className="mb-0 fw-normal">
                                12:10
                              </h5>
                              <p className="mb-0">
                                Dubai Dubai International (DXB)
                              </p>
                            </div>
                            <div className="d-flex align-items-center my-4 pl-5 ml-5">
                              <img loading='lazy' decoding='async'
                                width={20}
                                src={Emirates}
                                alt=""
                              />
                              <h5 className="mb-0 ml-2 text-gray-100 fw-normal">
                                Emirates
                              </h5>
                            </div>
                            <div className="bonding-time">
                              <h5 className="mb-0 fw-normal">
                                12:10
                              </h5>
                              <p className="mb-0">
                                Dubai Dubai International (DXB)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flight-options">
                        <ul className="p-0 m-0">
                          {flightOptions.map((item, i) => (
                            <li key={i}>
                              {item.icon}
                              <span>{item.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </>
      )
    }

    const flightDetailView = (selectedFlightDetail) => {
      const flightDetails = selectedFlightDetail?.segments?.reduce((result, segment) => {
        if (segment.segment_indicator === 1) {
          result["outBoundFlight"].push(segment)
        } else {
          result["inBoundFlight"].push(segment)
        }
        return result
      }, { outBoundFlight: [], inBoundFlight: [] })
      flightDetails['fare_local'] = selectedFlightDetail?.fare_local
      return <Card.Body className="px-0 pt-5 pb-0">

        {/* <div className="flight-routes mt-3">
      <Accordion className="w-100" defaultActiveKey="1">
        <Card className="w-100 rounded-0">
          <div className="flight-routes-info">
            <div className="airline-name">
              <div className="d-inline-block text-center">
                <img loading='lazy' decoding='async' src={Emirates} alt="" />
                <h5 className="text-gray-100">Emirates</h5>
              </div>
            </div>
            <div className="airline-time pl-4 pl-xl-0">
              <h6>12:10</h6>
              <p>DXB</p>
            </div>
            <div className="airline-direct text-center">
              <div className="airline-direct-inner">
                <span className="color-blue">Direct</span>
                <div className="direct"></div>
                <p>07h 35m</p>
              </div>
            </div>
            <div className="airline-time pl-lg-5 pl-4">
              <h5>07:05</h5>
              <p>LHR</p>
            </div>
            <div className="more-info text-center">
              <Accordion.Toggle
                as={Card.Header}
                className="p-0 border-0"
                eventKey="1"
                onClick={() => {
                  this.toggleHandler("second");
                }}
              >
                <img loading='lazy' decoding='async'
                  src={arrowup}
                  alt="arrow"
                  className={
                    this.state.toggle?.second
                      ? "toggleup"
                      : "toggledown"
                  }
                />
                {this.state.toggle?.second
                  ? "See less"
                  : "See more"}
              </Accordion.Toggle>
            </div>
          </div>
          <Accordion.Collapse eventKey="1">
            <Card.Body className="border-top">
              <div className="flight-info">
                <div className="d-flex align-items-center flex-fill">
                  <div className="flight-timeline"></div>
                  <div className="bonding-info flex-fill">
                    <PlaneUp />
                    <div className="pl-4 flex-fill">
                      <div className="bonding-time">
                        <h5 className="mb-0 fw-normal">
                          12:10
                        </h5>
                        <p className="mb-0">
                          Dubai Dubai International (DXB)
                        </p>
                      </div>
                      <div className="d-flex align-items-center my-4 pl-5 ml-5">
                        <img loading='lazy' decoding='async'
                          width={20}
                          src={Emirates}
                          alt=""
                        />
                        <h5 className="mb-0 ml-2 text-gray-100 fw-normal">
                          Emirates
                        </h5>
                      </div>
                      <div className="bonding-time">
                        <h5 className="mb-0 fw-normal">
                          12:10
                        </h5>
                        <p className="mb-0">
                          Dubai Dubai International (DXB)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flight-options">
                  <ul className="p-0 m-0">
                    {flightOptions.map((item, i) => (
                      <li key={i}>
                        {item.icon}
                        <span>{item.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div> */}
        <div className="d-flex">
          <div className="bounding-icon">
            <PlaneUp />
          </div>
          <div className="bounding">
            <h5>{t("Outbound Flight")} {moment(searchResults?.segments[0]?.departure_date).format("MMM DD")}</h5>
            <span>{flightDetails?.outBoundFlight[0]?.origin?.city} - {flightDetails?.outBoundFlight[flightDetails?.outBoundFlight?.length - 1]?.destination?.city}</span>
          </div>
        </div>
        {flightDetails?.outBoundFlight?.length > 1 ?
          <>
            <StepFlight flightDetails={flightDetails?.outBoundFlight} {...{ selectedFlightDetail, searchResults, flightOptions }} />
          </>
          :
          nonstopFlight(flightDetails?.outBoundFlight[0], selectedFlightDetail, false)
        }
        {flightDetails?.inBoundFlight.length ?
          flightDetails?.inBoundFlight?.length > 1 ?
            <>
              <div className="d-flex pt-4">
                <div className="bounding-icon">
                  <PlaneDown />
                </div>
                <div className="bounding">
                  <h5>{t("Inbound flight")} {moment(searchResults?.segments[1]?.departure_date).format("MMM DD")}</h5>
                  <span>{flightDetails?.inBoundFlight[0]?.origin?.city} - {flightDetails?.inBoundFlight[flightDetails?.inBoundFlight?.length - 1]?.destination?.city}</span>
                </div>
              </div>
              <StepFlight flightDetails={flightDetails?.inBoundFlight} {...{ selectedFlightDetail, searchResults, flightOptions }} />
            </>
            :
            nonstopFlight(flightDetails?.inBoundFlight[0], selectedFlightDetail, true)
          : null}
      </Card.Body>
    }

    const { t } = this.props
    return (
      searchResults?.segments ?
        <>
          {this.state.loading && <Loader />}
          <div className="d-md-none d-block">
            <TravelDetailTopBar takeOff="LHR" landing="DXB" desc="13 Oct - 10 Nov | 1 pax | Economy">
              <div className="flight-icon ml-auto" onClick={() => this.props.history.push('/flights')}>
                <EditButton />
              </div>
            </TravelDetailTopBar>
          </div>
          <Breadcrumbs crumbs={breadcrumbs} />
          <div className="d-md-flex py-2 d-none align-items-center">
            <div className="border mx-4 d-none d-md-inline-block d-flex justify-content-center align-items-center">
              <img loading='lazy' decoding='async' src={arrowImg} alt="arrow button" />
              <button className="btn m-0 p-2" onClick={() => this.props.history.push('/flights')}>{t("Back")}</button>
            </div>
            <div className="mx-auto text-center">
              <h5 className="fw-normal">{flightDetails?.outBoundFlight[0]?.origin?.city} - {flightDetails?.outBoundFlight[flightDetails?.outBoundFlight?.length - 1]?.destination?.city}</h5>
              <ul className="choose-flight-info d-flex align-items-center p-0 m-0">
                <li>
                  <Calendar />
                  <span>{moment(searchResults?.segments[0]?.departure_date).format("MMM DD")} {searchResults?.segments[1] ? `- ${moment(searchResults?.segments[1]?.departure_date).format("MMM DD")}` : ''}</span>
                </li>
                <li>
                  <User />
                  <span>{this.props.searchQueryData?.passangerCount} </span>
                </li>
                <li>
                  <Economy />
                  <span>{`${t(this.props.searchQueryData?.passengerClass)}`}</span>
                </li>
                <li onClick={() => this.props.history.push('/flights')} className='cursor-pointer'>
                  <EditButton />
                  <span>{t("Change")}</span>
                </li>
                {/* {flightInfo.map((data, i) => (
                  <li key={i}>
                    {data.icon}
                    <span>{data.item}</span>
                  </li>
                ))} */}
              </ul>
            </div>
          </div>
          <div className="bg-flightGray col-12 px-md-4 py-md-4" ref={this.myRef}>
            <Row className="justify-content-center">
              <Col xl={8} lg={10} md={12}>
                <Card className="w-100 rounded-0 mb-5">
                  <Accordion className="w-100" defaultActiveKey="0">
                    <Card className="w-100 p-lg-5 p-sm-4 p-3 rounded-0">
                      <Accordion.Toggle
                        as={Card.Header}
                        className="p-0 border-0"
                        eventKey="0"
                        onClick={() => {
                          this.toggleHandler("first");
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="flight-collapse">
                            <PlaneTicket />
                            <div className="flight-collapse-header">
                              <h4 className="color-blue fw-normal m-0">
                                {t("Your selected flight")}
                              </h4>
                              {/* <span>Flight found on Kayak</span> */}
                            </div>
                          </div>
                          <div className="flight-collapse-arrow">
                            <img loading='lazy' decoding='async'
                              src={arrowup}
                              alt="arrow"
                              className={
                                this.state.toggle?.first
                                  ? "toggleup"
                                  : "toggledown"
                              }
                            />
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        {flightDetailView(selectedFlightDetail?.search?.data)}
                      </Accordion.Collapse>
                      <Row className="align-items-center pt-4">
                        <Col lg={5} md={4}>
                          <div className="text-right">
                            {console.log(`flightDetails 5566`, flightDetails, selectedFlightDetail)}
                            <p className="mb-0">{t(`${flightDetails?.inBoundFlight?.length ? 'Round' : 'One way'} trip price`)}</p>
                            <span className="text-gray-100">{t("Per person incl taxes")}</span>
                          </div>
                        </Col>
                        <Col lg={4} md={5}>
                          <div className="text-right">
                            <h3 className="fw-normal mb-0">{numberWithCommas(flightDetails?.fare_local?.price_in_points)} {t("Points")}</h3>
                            {/* <p className="mb-0">Missing Points </p> */}
                            {missingPoint > 0 ? <p className="mb-0">{t("Missing Points")}  <span className="color-green">{`(${numberWithCommas(missingPoint)} Points)`}</span></p> : null}
                          </div>
                        </Col>
                        <Col lg={3} md={3}>
                          <Button block className="py-2 mt-md-0 mt-2 btn-sky border-white" onClick={() => this.props.history.push('/contacts')}>{t("Book Flight")} <ArrowRight width={8} className="ml-2" /></Button>
                        </Col>
                      </Row>
                    </Card>
                  </Accordion>
                </Card>
                <Card className="w-100 rounded-0 mb-5">
                  <Card.Header className="flight-title p-4">
                    <h6 className="text-center mb-0 fw-normal">{t("Other Flights")}</h6>
                  </Card.Header>
                </Card>
                <Card className="w-100 pt-md-5 pt-3 px-md-5 px-3 pb-3 mb-4 rounded-0">
                  <Card.Header className="px-0 pb-4">
                    <div className="d-flex">
                      <div className="bounding-icon">
                        <PlaneUp />
                      </div>
                      <div className="bounding">
                        <h5>{t("Outbound Flight")} {moment(searchResults?.segments[0]?.departure_date).format("MMM DD")}</h5>
                        <span>{flightDetails?.outBoundFlight[0]?.origin?.city} - {flightDetails?.outBoundFlight[flightDetails?.outBoundFlight?.length - 1]?.destination?.city}</span>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="px-0 pb-0">
                    <div className="align-items-center justify-content-between d-flex">
                      <h5 className="flight-person-count mb-0">
                        <b>{(searchResults?.search.length - 1) || 0} {t("Flights")}</b> - {t(`${flightDetails?.inBoundFlight?.length ? 'Round' : 'One way'} trip price per person`)}
                      </h5>
                      <div className="pl-4 d-flex align-items-center dropdown w-auto ml-auto">
                        <span className="align-middle mr-3">{t("Sort")}:</span>
                        <b
                          className="btn btn-outline px-0 dropdown-toggle dropdown-arrow btncategory btnTxt text-capitalize"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          {t(this.props.searchOrder)}
                        </b>
                        <div className="dropdown-menu pre-scrollable custom-scrollbar" aria-labelledby="dropdownMenuButton">
                          {
                            [{ name: 'Best', value: 'best' }, { name: 'Cheapest', value: 'cheapest' }, { name: 'Quickest', value: 'quickest' }].map((data, i) => (
                              <span className="dropdown-item" value={data.value} onClick={() => this.props.searchOrder !== data.value && this.changeOrder(data)}> {t(data.name)} </span>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                {/* {
                  OutBoundFlightList.map((data, i) => (
                    <OutBoundFlight
                      key={i}
                      flightImage={data.flightImage}
                      points={data.points}
                      totalTime={data.totalTime}
                      stops={data.stops}
                      takeOffLocation={data.takeOffLocation}
                      landingLocation={data.landingLocation}
                      takeOffTime={data.takeOffTime}
                      landingTime={data.landingTime}
                    />
                  ))
                } */}
                {
                  searchResults?.search?.map((data, i) => (
                    data.resultID !== this.props.selectedFlightDetail?.search?.resultID ?
                      <>
                        <Card className="w-100 pt-3 px-3 pb-3 rounded-0 mb-4">

                          <OutBoundFlight
                            data={data}
                            segments={searchResults.segments}
                            // handleViewDetail={() => this.props.history.push('/contacts')}
                            handleViewDetail={this.handleViewDetail}
                            key={i}
                            toggle={this.state.toggle}
                            flightImage={data.flightImage}
                            points={data.points}
                            totalTime={data.totalTime}
                            stops={data.stops}
                            takeOffLocation={data.takeOffLocation}
                            landingLocation={data.landingLocation}
                            takeOffTime={data.takeOffTime}
                            landingTime={data.landingTime}
                            handleViewFlightDetail={this.toggleHandler}
                          />
                          {this.state.toggle[data?.resultID] ? <>
                            {flightDetailView(data)}
                          </> : null}
                        </Card>
                      </>
                      : null
                  ))
                }
              </Col>
            </Row>
          </div>
        </> : null
    );
  };
  MobileView = () => {
    const flightOptions = [
      {
        icon: <Baggage />,
        name: "Check-in baggage: 30 kg",
      },
      {
        icon: <Baggage />,
        name: "Hand baggage: Pieces 1, Total weight 7 kg",
      },
      {
        icon: <Meal />,
        name: "Standard Meal of your choice",
      },
      {
        icon: <Economy />,
        name: "Standard seat selection",
        className: 'disabled'
      },
    ];
    const OutBoundFlightList = [
      {
        flightImage: Emirates,
        flightName: 'British Airways',
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '(Direct)',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '9,910'
      },
      {
        flightImage: Qatar,
        flightName: 'British Airways',
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '(Direct)',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '7,520'
      },
      {
        flightImage: Eagle,
        flightName: 'British Airways',
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '(Direct)',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '9,490'
      },
      {
        flightImage: Qatar,
        flightName: 'British Airways',
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '(Direct)',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '7,520'
      },
      {
        flightImage: Eagle,
        flightName: 'British Airways',
        takeOffTime: '12:10',
        landingTime: '07:05',
        totalTime: '07h 35m',
        stops: '(Direct)',
        takeOffLocation: 'LHR',
        landingLocation: 'DXB',
        points: '9,490'
      },
    ]

    const { searchResults, selectedFlightDetail, t } = this.props
    const { flightDetails } = this.state

    return (
      <>
        <TravelDetailTopBar takeOff="LHR" landing="DXB" desc="13 Oct - 10 Nov | 1 pax | Economy">
          <div className="flight-icon ml-auto">
            <EditButton />
          </div>
        </TravelDetailTopBar>
        <div className="p-4">
          <h4 className="text-purple mb-0">Your flight details</h4>
        </div>
        <div className="border-bottom">
          <div className="d-flex align-items-start px-4">
            <img loading='lazy' decoding='async' className="mt-1" src={Emirates} alt="" width={20} />
            <h6 className="text-purple ml-3">EK 5 (Operated by Emirates Airlines) Boing 777 ( 7h 40m )</h6>
          </div>
          <div className="mt-3 px-5">
            <div className="flight-mobile-info px-3">
              <p>Wednesday, 13 Ocober 2021</p>
              <p>Dubai International Airport, Dubai</p>
              <div className="airline-time-info">
                <PlaneUp /> <span>23:00 DXB</span>
              </div>
            </div>
            <div className="flight-mobile-info px-3">
              <p>Friday, 10 November 2021</p>
              <p>Heathrow Airport, London</p>
              <div className="airline-time-info">
                <PlaneDownRight /> <span>23:00 DXB</span>
              </div>
            </div>
          </div>
          <div className="flight-options border-top pl-5 pr-4 py-3">
            <ul className="pl-2 m-0">
              {flightOptions.map((item, i) => (
                <li key={i} className={item.className}>
                  {item.icon}
                  <span>{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="text-right p-3">
          <h3 className="fw-normal mb-2">18,490 <small>{t("pts")}</small></h3>
          <p className="mb-1 color-blue">{t("Missing Points")} 8,490 (365 SAR)</p>
          <p>{t("to be paid by Credit Card")}</p>
          <Button block className="py-3" onClick={() => this.props.history.push('/contacts')}>{t("Book Flight")}</Button>
        </div>
        <div className="px-3">
          {
            OutBoundFlightList.map((data, i) => (
              <OutBoundFlight
                key={i}
                flightImage={data.flightImage}
                flightName={data.flightName}
                points={data.points}
                totalTime={data.totalTime}
                stops={data.stops}
                takeOffLocation={data.takeOffLocation}
                landingLocation={data.landingLocation}
                takeOffTime={data.takeOffTime}
                landingTime={data.landingTime}
              />
            ))
          }
        </div>
      </>
    );
  };
  render() {
    const { searchError } = this.state;
    return (
      <>
        <div>
          {
            searchError ? <FailMessageModal show={!!searchError} Message={searchError} customError onClose={() => {this.setState({searchError:""})}} ShowheaderText={false} path="/flights"/> : null
          }
          {/* <div className="d-md-none d-block" style={{ paddingBottom: 80 }}>
            <this.MobileView />
          </div>
          <div className="d-md-block d"> */}
          <this.DesktopView />
          {/* </div> */}
        </div>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    selectedFlightDetail: state.travel.selectedFlightDetail,
    searchResults: state.travel.searchResults,
    searchOrder: state.travel.searchOrder,
    searchQueryData: state.travel?.searchQueryData,
    point: state.point.pointBalance,
    authToken: state.auth.authToken,
    currancyCode:state.countries.convertionRate.currency_code
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedFlightDetails: (data) => dispatch(setSelectedFlightDetails(data)),
    setSearchOrder: (data) => dispatch(setSearchOrder(data)),
    fetchAuthTokenSuccess: (data) => dispatch(fetchAuthTokenSuccess(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(FlightsDetails)));
