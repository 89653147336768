import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Pagination } from "react-bootstrap";
import { withRouter } from "react-router";
import InfoIcon from '../../assests/img/TravelShop/info.svg'
import SortIcon from '../../assests/img/TravelShop/sort-icon.svg'
import Emirates from '../../assests/img/TravelShop/emirates.svg'
import British from '../../assests/img/TravelShop/british.svg'
import TravelShopFlightsBox from "./TravelShopFlightsBox";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from '../../assests/img/TravelShop/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../assests/img/TravelShop/arrow-right.svg'
import ReactPaginate from "react-paginate";
import axios from "axios";
import Loader from "./Loader";
import Top from "../../assests/img/top.png"
import { setFilterData, setSearchOrder, setSelectedFlightDetails } from "../../Redux/travelShop/TravelAction";
import { REACT_APP_baseUrl } from "utils/app-constants";

class TravelShopDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      searchData: [],
      count: 50,
      offset: 0,
      endOffset: 5,
      itemsPerPage: 10,
      page:0,
      initPage:null,
      visible:false
    }
  }

  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled >= 3500){
      this.setState({visible:true})
    }else{
      this.setState({visible:false})
    }  
  };

  componentDidMount = () => {
    this.handleSearchData(this.props)
    this.props.viewCount(this.state.itemsPerPage)
    window.addEventListener('scroll', this.toggleVisible);
   
  }

  componentWillReceiveProps = (props) => {
    this.handleSearchData(props)
  }
  
  componentDidUpdate = (prevProps) =>{
    if((prevProps.searchResults.flight_count !== this.props.searchResults.flight_count) || (prevProps.searchOrder !== this.props.searchOrder)){
      const count = Math.ceil(this.props?.searchResults?.flight_count / this.state.itemsPerPage)
      this.setState({count:count})
      this.props.viewCount(10)
      this.setState({initPage:this.state.initPage + 1},()=>{
        this.setState({initPage:0})
      })
    }
  }

  handleSearchData = (props = false) => {
    const data = props ? JSON.parse(JSON.stringify(props.searchResults.search)) : JSON.parse(JSON.stringify(this.props.searchResults.search))
    if (data.length) {
      const count = Math.ceil(this.props.searchResults.flight_count / this.state.itemsPerPage)
      this.setState((state) => ({ ...state, count: count, searchData: [...data] }))
      
    }
  }

  changeOrder = (order,page) => {
    if (this.props.searchOrder !== order.name || page >=0) {
      let payload
      if(this.props.mobileDevice){
        page = this.props.searchResults?.search?.length
        payload = { ...this.props.filters, order: order.name, offset:page ? page : 0 }
      }else{
        payload = { ...this.props.filters, order: order.name, limit:this.state.itemsPerPage, offset:page ? page : 0 }
      }
      const search_id = localStorage.getItem('search_id')
      this.props.setSearchOrder(order.name)
      this.props.setFilterData(payload)

      setTimeout(() => {
        this.props.fetchSearch({
          search: {
            search_id,
            query_params: payload
          }
        }, false, order.name)
      }, 300);
    }
  }

  handlePageClick = (event) => {
    const newOffset = event.selected * this.state.itemsPerPage % this.state.searchData.length;
    // setItemOffset(newOffset);
    // itemOffset + this.state.itemsPerPage
    this.setState({
      ...this.state,
      offset: newOffset,
      endOffset: newOffset + this.state.itemsPerPage,
    })
    const data = {
      "name" : this.props.searchOrder
    }
    let offset = event.selected
    if(offset>=1){
      offset = offset * 10 
    }
    this.changeOrder(data,offset)

    
    let pageData = event.selected+1
    if(event.selected+1===this.state.count){
      this.props.viewCount(this.props.searchResults?.flight_count)
    }else{
      this.props.viewCount(pageData * 10)
    }
  };

  handleViewDetail = (result_id) => {
    const token = localStorage.getItem("userToken");
    const search_id = localStorage.getItem("search_id");
    const payload = {
        search: {
            search_id: search_id,
            result_id: result_id,
            update_information: false
        }
    }
    this.setState({ loading: true })
    axios.post(`${REACT_APP_baseUrl}/travel_shops/post_search_by_result_id`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-customer-uuid' : localStorage.getItem("customer_uuid")
            },
        }
    )
        .then((response) => {
            axios.get(`${REACT_APP_baseUrl}/travel_shops/get_search_detail?search_id=${search_id}&result_id=${result_id}&currency=${this.props.currancyCode}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'x-customer-uuid' : localStorage.getItem("customer_uuid")
                    },
                }
            ).then((response) => {
                if (response.data?.data) {
                    localStorage.setItem("result_id", result_id);
                    this.props.setSelectedFlightDetails(response.data?.data)
                    this.setState({ loading: false })
                    this.props.history.push('/flights-details')
                } else {
                    if (response.data?.code === 401) {
                        this.props.fetchAuthTokenSuccess('')
                        this.setState({ loading: false })
                    } else {
                        this.setState({ loading: false, searchError: response.data?.message || 'Something went wrong, please try again later!' })
                    }
                }
                // this.setState({ countries: filteredAirports, loading: false })
            }).catch(err => {
                
                this.setState({ loading: false, searchError: err?.response?.data?.message || 'Something went wrong, please try again later!' })
            })
        })
        .catch((err) => {
            
            this.setState({ loading: false, searchError: err?.response?.data?.message || 'Something went wrong, please try again later!' })
        })
};

  toggleScroll = () =>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  }

  DesktopView = () => {
    const TabList = [
      {
        tabName: 'Best',
        className: 'active',
        name: 'best',
        icon: InfoIcon,
        points: 'best_price_in_points'
      },
      {
        tabName: 'Cheapest',
        name: 'cheapest',
        points: 'cheapest_price_in_points'
      },
      {
        tabName: 'Quickest',
        name: 'quickest',
        points: 'quickest_price_in_points'
      }
    ]
    const FlightsList = [
      {
        flightImage: Emirates,
        returnFlightImage: British,
        flightName: 'Emirates',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'DXB'],
        landing: ['4:30 pm', 'LHR'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490',
        className: "popular"
      },
      {
        flightImage: British,
        returnFlightImage: British,
        best: 'Best',
        fees: 'No change fees',
        stops: '2 Stops',
        flightName: 'British Airways',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'LHR'],
        landing: ['4:30 pm', 'DXB'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490'
      },
      {
        flightImage: British,
        returnFlightImage: British,
        best: 'Best',
        fees: 'No change fees',
        stops: '2 Stops',
        flightName: 'British Airways',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'LHR'],
        landing: ['4:30 pm', 'DXB'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490'
      },
      {
        flightImage: British,
        returnFlightImage: British,
        best: 'Best',
        fees: 'No change fees',
        stops: '2 Stops',
        flightName: 'British Airways',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'LHR'],
        landing: ['4:30 pm', 'DXB'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490'
      },
      {
        flightImage: British,
        returnFlightImage: British,
        best: 'Best',
        fees: 'No change fees',
        stops: '2 Stops',
        flightName: 'British Airways',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'LHR'],
        landing: ['4:30 pm', 'DXB'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490'
      },
    ]
    const { searchResults, t } = this.props
    return (
      <>
        {this.state.loading && <Loader />}
        <div className="flights-tab-results d-flex align-items-center bg-white border px-2 shadow-sm">
          {
            TabList.map((data, i) => (
              <div className={`flights-tab-item ${data.name === this.props.searchOrder ? 'active' : ''}`} key={i} onClick={() => this.changeOrder(data)}>
                <h5><span>{t(data.tabName)}</span>
                  {data.icon && <img loading='lazy' decoding='async' src={data.icon} alt="" />}
                </h5>
                <span>{searchResults?.filtered_metadata?.fare_local && searchResults?.filtered_metadata?.fare_local?.[data.points]} {t("Points")}</span>
              </div>
            ))
          }
        </div>
        <div className="flights-lists">
          {/* {
            FlightsList && FlightsList.length > 0 && FlightsList.map((data, i) => (
              <TravelShopFlightsBox
                className={data.className}
                key={i}
                flightImage={data.flightImage}
                returnFlightImage={data.returnFlightImage}
                best={data.best}
                fees={data.fees}
                stops={data.stops}
                flightName={data.flightName}
                returnFlightName={data.returnFlightName}
                takeOff={data?.takeOff}
                landing={data?.landing}
                returnFlightTakeOff={data?.returnFlightTakeOff}
                returnFlightLanding={data?.returnFlightLanding}
                totalTime={data.totalTime}
                returnFlightTotalTime={data.returnFlightTotalTime}
                points={data.points}
              />
            ))
          } */}
          {
            searchResults?.search?.map((data, i) => (
              <TravelShopFlightsBox
                data={data}
                segments={searchResults?.segments}
                point={this.props.point}
                className={data.className}
                key={i}
                flightImage={data.flightImage}
                returnFlightImage={data.returnFlightImage}
                best={data.best}
                fees={data.fees}
                stops={data.stops}
                flightName={data.flightName}
                returnFlightName={data.returnFlightName}
                takeOff={data?.takeOff}
                landing={data?.landing}
                returnFlightTakeOff={data?.returnFlightTakeOff}
                returnFlightLanding={data?.returnFlightLanding}
                totalTime={data.totalTime}
                returnFlightTotalTime={data.returnFlightTotalTime}
                points={data.points}
                handleViewDetail={this.props.handleViewDetail}
              />
            ))
          }
          <ReactPaginate
            className="justify-content-end mt-4 pagination"
            nextLabel={<ArrowRight />}
            onPageChange={this.handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={this.state.count}
            forcePage={this.state.initPage}
            previousLabel={<ArrowLeft />}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="prev page-link"
            nextClassName="page-item"
            nextLinkClassName="next page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
          {/* <ul className="pagination justify-content-end mt-5">
            <li className="page-item prev disabled">
              <Link className="page-link" href="#"><ArrowLeft /></Link>
            </li>
            <li className="page-item active">
              <Link className="page-link" href="#">1</Link>
            </li>
            <li className="page-item">
              <Link className="page-link" href="#">2</Link>
            </li>
            <li className="page-item">
              <Link className="page-link" href="#">3</Link>
            </li>
            <li className="page-item">
              <Link className="page-link" href="#">4</Link>
            </li>
            <li className="page-item next">
              <Link className="page-link" href="#"><ArrowRight /></Link>
            </li>
          </ul> */}
        </div>
      </>
    );
  };
  MobileView = () => {
    const {t} = this.props
    return (
      <>
        {this.state.loading && <Loader />}
        <div className="flights-lists">
          { 
            (this.props.searchResults?.search?.length >= 20 && this.state.visible) &&
            <div className="scroll-top">
              <img src={Top} alt="top" className="img-fluid" onClick={this.toggleScroll} />
            </div>
          }
          {
            this.props.searchResults?.search?.map((data, i) => (
              <TravelShopFlightsBox
                data={data}
                segments={this.props.searchResults.segments}
                point={this.props.point}
                className={data.className}
                key={i}
                flightImage={data.flightImage}
                returnFlightImage={data.returnFlightImage}
                best={data.best}
                fees={data.fees}
                stops={data.stops}
                flightName={data.flightName}
                returnFlightName={data.returnFlightName}
                takeOff={data?.takeOff}
                landing={data?.landing}
                returnFlightTakeOff={data?.returnFlightTakeOff}
                returnFlightLanding={data?.returnFlightLanding}
                totalTime={data.totalTime}
                returnFlightTotalTime={data.returnFlightTotalTime}
                points={data.points}
                handleViewDetail={this.handleViewDetail}
              />
            ))
          }
          {
            this.props.searchResults?.search?.length !== this.props.searchResults?.flight_count && 
            <div className="text-center my-2">
              <button className="btn primary-btn" onClick={()=>this.changeOrder("best",20,true)}>{t("Load More")}</button>
            </div>
          }
          {/* <ReactPaginate
            className="justify-content-end mt-4 pagination"
            nextLabel={<ArrowRight />}
            onPageChange={this.handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={this.state.count}
            forcePage={this.state.initPage}
            previousLabel={<ArrowLeft />}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="prev page-link"
            nextClassName="page-item"
            nextLinkClassName="next page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          /> */}
        </div>
        {/* <div className="flights-lists">
          {
            this.props.searchResults?.search?.slice(this.state.offset, this.state.endOffset)?.map((data, i) => (
              <TravelShopFlightsBox
                data={data}
                segments={this.props.searchResults.segments}
                className={data.className}
                key={i}
                flightImage={data.flightImage}
                returnFlightImage={data.returnFlightImage}
                best={data.best}
                fees={data.fees}
                stops={data.stops}
                flightName={data.flightName}
                returnFlightName={data.returnFlightName}
                takeOff={data?.takeOff}
                landing={data?.landing}
                returnFlightTakeOff={data?.returnFlightTakeOff}
                returnFlightLanding={data?.returnFlightLanding}
                totalTime={data.totalTime}
                returnFlightTotalTime={data.returnFlightTotalTime}
                points={data.points}
                handleViewDetail={this.props.handleViewDetail}
              />
            ))
          }
          <ReactPaginate
            nextLabel="next >"
            onPageChange={this.handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={this.state.count}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div> */}
      </>
    );
  };

  render() {
    return (
      <div>
        <div className="d-md-none d-block">
          <this.MobileView />
        </div>
        <div className="d-md-block d-none">
          <this.DesktopView />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchResults: state.travel.searchResults,
    point: state.point.pointBalance,
    searchOrder: state.travel.searchOrder,
    filters: state.travel.filters,
    currancyCode:state.countries.convertionRate.currency_code
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedFlightDetails: (data) => dispatch(setSelectedFlightDetails(data)),
    setSearchOrder: (data) => dispatch(setSearchOrder(data)),
    setFilterData: (data) => dispatch(setFilterData(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TravelShopDetails)));
