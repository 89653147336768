import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import '../../assests/css/App.css';
import '../../assests/css/Giftsection.css';
import Breadcrumbs from '../shared/Breadcrumbs';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import filterIcon from '../../assests/img/header/icon-filter-categories.png'
import { getBrandData, getCategoriesByModule } from 'Redux/brand/brandActions';

class GiftCardsPage extends Component {

    constructor(props) {
        super(props);
        // if (props.location.state !== null && props.location.state !== undefined) {
        //     this.referrer = props.location?.state?.referrer
        // }

        this.state = {
            // information: this.referrer,
            clicked: false,
            giftcards: [],
            cardsDetails: [],
            letter: 'Default',
            sortedGiftCards: undefined,
            catName: 'All',
            pageSize: 20,
            pageNumber: this.props.pageNumber,
            loadPage:true
        }
    }
    componentDidMount() {
        if(this.props?.giftCards?.length > 0) {
            this.setState({cardsDetails:this.props.giftCards, loadPage:false})
        }
            if(!this.props.loading &&  this.props.giftCards?.length === 0){
                this.props.history.push({
                    pathname:'/404',
                    redirectionURL:'/giftcards'
                })
            }
            if(this.props.giftCardCategories?.length > 0) return
            const params = {
                module : "GIFTCARD",
                currency_id : this.props.countrySelected
            }
            getCategoriesByModule(params)
    }

    componentDidUpdate = (prevProps, prevState) => {

        if ((prevProps.currancyCode !== this.props.currancyCode)) {
            this.setState({ sortedGiftCards: undefined, letter: 'Default', loadPage : true, pageNumber : 1 })
        }
        if(prevProps.giftCards !== this.props.giftCards){
            this.setState({
                cardsDetails:this.props.giftCards})
        }
        if (!this.props.loading && this.props.giftCards?.length === 0){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/giftcards'
            })
        }
        if(prevProps.lang !== this.props.lang){
            this.setState({pageload:true})
        }
    }

    fetchNextPage(isReset) {
        this.setState({loadPage: false ,pageNumber: this.props?.pageNumber+1},()=>{
            this.fetchCards(isReset,this.state.pageNumber)
        })
    }

    fetchCards = (isReset,pageNumber) =>{
        const params = {
            "currency": this.props.selectedCountry.currencyCode,
            "pageNumber": this.state.pageNumber,
            "pageSize": this.state.pageSize
        }
        this.props.getBrands(params, isReset,pageNumber)
    }


    render() {
        const { t } = this.props;
        const breadcrumbs=[
            {name:t("Home"),url:"/",active:true},
            {name:t("Giftcards"),url:"",active:false}
        ]

        const giftcards = this.state.cardsDetails?.map((card, index) => {
            return <div className="text-center border-0" key={card?.id}>
                <Link className="active p-0 "
                    data-testid={`giftCard-${index}`}
                    to={{
                        pathname: `/selectedgiftcard/${card?.id}`,
                        state: { ...card }
                    }}>
                    <img className="img-fluid card-hover" src={card?.images?.color?.medium_rectangle} alt={card?.name} />
                </Link>
                <div className='text-center py-3 product_title fw-bold' >
                    {card?.name}
                </div>
                {card?.rates === null ?
                    <div className='text-danger fw-bold ' style={{ fontSize: "12px" }}>{t("Sold Out")}...... {t("Coming Soon")}</div>
                    : false
                }
            
        </div>
        })
        
        const desktopView = (
            <div className='d-md-block d-none'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">{t("Gift Cards")}</div>
                    </div>
                    {/* <div className={`${this.props.lang === 'ar' ? "" : "ml-5"} `} >
                        <Dropdown className='d-inline '>
                            <DropdownToggle style={{ width: '8rem' }} className={`${this.props.lang === 'ar' ? "float-left" : ""} text-light rounded-0 mt-1 px-0 bg-white no-outline`}>
                                <span className='second-color fw-bold float-left px-3'>{`${t(this.state.letter)}`}</span>
                                <span className='arrow-down color-skyblue float-right'></span>
                            </DropdownToggle>
                           <DropdownMenu>
                            {
                                    letters.map((e)=>(
                                        <DropdownItem key={e}
                                        onClick={()=>  
                                            this.sortGiftCards(totalGiftCards,e)}
                                            >
                                        {t(e)}</DropdownItem>
                                    ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                    </div> */}
                </div>
            </div>
           
                    )
        const mobileView =(
            <>
                <div className='d-block d-md-none'>
                    <div>
                        <span className='fs-3 headings fw-bold'>{t("Gift Cards")}</span>
                        <span className='fs-5 text-muted'> - {t(this.state.catName)}</span>
                    </div>
                    <div className='d-flex flex-row justify-content-end py-2'>

                        <div>
                            <div style={{ maxWidth: "50px", marginRight: "16px", maxHeight: "40px" }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-flip="false">
                                <img style={{ minHeight: "31px", minWidth: "31px" }} src={filterIcon} alt="categorie" className="img-fluid" />
                            </div>

                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <DropdownItem onClick={() => this.props.history?.push("/giftcards")}>{t("All")}</DropdownItem>
                                {
                                    this.props.giftCardCategories?.length > 0 && this.props.giftCardCategories.map(cate => (
                                            <Link
                                                to={`/giftcards/${cate?.name}/${cate?.id}`}
                                                key={cate?.name}
                                                className="dropdown-item">{t(cate?.name)}</Link>
                                        ))
                                }
                            </div>
                      </div>
                    </div>
                </div>
             </>                                  
        )

        return (
            <>
                {(this.props.loading && this.state.loadPage) ? <section>
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        </section> :

                <div>
                    <Breadcrumbs crumbs={breadcrumbs} />
                    <section className="px-md-5 px-3 py-md-4 py-1">
                        {desktopView}
                        {mobileView}
                        <div className='d-flex gap-3'>

                            <div className='d-md-block d-none col-2 p-0'>
                                    <div className='fw-bold '>
                                        <span className='fs-5 headings fw-bold'>{t("Categories")}</span>
                                        <div className='text-muted d-flex flex-column gap-2 py-3'>


                                            {
                                                <Link to="/giftcards" className='category color-skyblue'>{t("All Products")}</Link>
                                            }
                                            {
                                                this.props.giftCardCategories?.length > 0 && this.props.giftCardCategories.map(category => <Link
                                                key={category}
                                                    to={`/giftcards/${category?.name}/${category?.id}`}
                                                    >{t(category?.name)}</Link>)
                                            }
                                        </div>
                                    </div>                        
                                </div>
                                <div>
                                    <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-2 ">
                                        {giftcards}
                                    </div>
                                    {
                            this.props.brandDataTotalRecord?.length !== this.state.cardsDetails?.length
                                ?
                                <div className='d-flex justify-content-center w-100 mt-3' style={{marginBottom:"7rem"}}>
                                    <button disabled={this.props.loading} className='btn primary-btn no-outline' onClick={() => this.fetchNextPage()}>
                                        {
                                            this.props.loading ? "Loading...." : "Load More!"
                                        }
                                    </button>
                                </div>
                                : null
                        }
                                </div>
                        </div>
                        
                    </section>
                </div>
    }
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        giftCards: state.brand.brandData,
        giftCardCategories: state.brand.categories,
        pageNumber: state.brand.pageNumber,
        brandDataTotalRecord: state.brand.brandDataTotalRecord,
        loading: state.brand.loading,
        letter: state.giftCards.letter,
        countrySelected: state.countries.countryId,
        currancyCode: state.countries.convertionRate.currency_code,
        countriesloading: state.countries.loading,
        fetchError: state.brand.error,
        selectedCountry: state.countries.selectedCountry,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getBrands: (params, isReset, pageNumber) => dispatch(getBrandData(params, isReset, pageNumber)),
        getCategoriesByModule: (params) => dispatch(getCategoriesByModule(params)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GiftCardsPage));
