import axios from "axios";
import secretKeyGenerator from "../../utils/serectKeyGenerator";
import { FETCH_MOBILECOUNTRIES_FAILURE, FETCH_MOBILECOUNTRIES_REQUEST, FETCH_MOBILECOUNTRIES_SUCCESS,
    FETCH_SERVICES_FAILURE, FETCH_SERVICES_REQUEST, FETCH_SERVICES_SUCCESS, FETCH_TERMS_TOPUP} from "./mobileType"
    import { REACT_APP_baseUrl } from 'utils/app-constants';
import store from '../../Redux/store';


export const fetchCountiriesTopUpRequest = () => {
    return {
        type:FETCH_MOBILECOUNTRIES_REQUEST,
    }
}

export const fetchCountiriesTopUpSuccess = data => {
    return {
        type:FETCH_MOBILECOUNTRIES_SUCCESS,
        payload:data
    }
}

export const fetchCountiriesTopUpFailure = error =>{
    return {
        type:FETCH_MOBILECOUNTRIES_FAILURE,
        payload:error
    }
}


const cacheHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }
    }
  }
 export const fetchTerms  = (id) =>{
     const payload ={
        "brands": {
            "id": id,
            "currency": store?.getState()?.countries?.countryId
        }

     }
      return (dispatch)  =>   axios.post(`${REACT_APP_baseUrl}/brands/terms`,
      payload,
      {
        params:{
            "secret_key":secretKeyGenerator(payload)
        }
    }
      )
      .then(response => {
          if(response.data.code === 200){
              dispatch({
                  type:FETCH_TERMS_TOPUP,

                  payload:response.data?.data?.terms
              })
          }
      })
 }
export const fetchCountiriesTopUp = () => {
    return (dispatch)=>{
        dispatch({type:FETCH_MOBILECOUNTRIES_REQUEST})
        dispatch(fetchCountiriesTopUpRequest);
        axios.post(`${REACT_APP_baseUrl}/countries/list_of_topup_countries`, cacheHeaders())
            .then(response  => {
                if(response.data?.code === 200){
                    const country = response.data.data.countries;
                    dispatch(fetchCountiriesTopUpSuccess(country))
                }
                else {
                    dispatch(fetchCountiriesTopUpFailure("failed"))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchCountiriesTopUpFailure("failed"))
            })
    }
}

export const fetchServicesRequest = () => {
    return {
        type:FETCH_SERVICES_REQUEST,
    }
}

export const fetchServicesSuccess = data => {
    return {
        type:FETCH_SERVICES_SUCCESS,
        payload:data
    }
}

export const fetchServicesFailure = error =>{
    return {
        type:FETCH_SERVICES_FAILURE,
        payload:error
    }
}

export const fetchServices = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_SERVICES_REQUEST})
        axios.post(`${REACT_APP_baseUrl}/brands/topup_brands`,
        payload,
        {
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        }).then(response  => {
            if (response.data?.code === 200) {
                const services = response.data.data.brands
                if (services.length > 0) {
                    dispatch(fetchServicesSuccess(services))
                    dispatch(fetchCountiriesTopUp())
                    dispatch(fetchTerms(services[0]?.id))
                }
                else {
                    dispatch(fetchServicesSuccess(services))
                }
            }
            else {
                dispatch(fetchServicesFailure("failed"))
            }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchServicesFailure("failed"))
            })
    }
}
