/* eslint-disable no-unused-vars */
import axios from "axios"
import { FETCH_AUTH_TOKEN_FAILURE,FETCH_TOKEN_REQUEST,FETCH_AUTH_TOKEN_INVALID, FETCH_AUTH_TOKEN_REQUEST, FETCH_AUTH_TOKEN_SUCCESS, VALID_TOKEN,DELETE_TOKEN, REMOVE_ERROR_MESSAGE, SIGN_UP_MESSAGE,SIGN_UP_MESSAGE_CLEAR, LOGIN_FLOW_SUCCESS } from "./authTypes"
import cookie from 'react-cookies';

import { REACT_APP_baseUrl } from 'utils/app-constants';
import { fetchPointBalance } from "Redux/point/pointActions";
import { RESET_CLEAR } from "Redux/PasswordReset/ResetTypes";
import { setCountryHandler, setLanguagesHandler } from "Redux/country/countriesAction";
import store from "Redux/store";

export const fetchAuthTokenRequest = () => {
    // if(localStorage.getItem('userToken')){
    //     localStorage.removeItem('userToken')
    // }
    return {
        type:FETCH_AUTH_TOKEN_REQUEST,
    }
}
export const fetchTokenRequest = () => {
    // if(localStorage.getItem('userToken')){
    //     localStorage.removeItem('userToken')
    // }
    return {
        type:FETCH_TOKEN_REQUEST,
    }
}

export const fetchAuthTokenSuccess = data => {
    return {
        type:FETCH_AUTH_TOKEN_SUCCESS,
        payload:data
    }
}
export const fetchAuthTokenInvalid = data => {
    return {
        type:FETCH_AUTH_TOKEN_INVALID,
        payload:data
    }
}

export const fetchAuthTokenFailure = error =>{
    return {
        type:FETCH_AUTH_TOKEN_FAILURE,
        payload:error
    }
}

export const removeErrorMessage = payload => {
    return {
        type: REMOVE_ERROR_MESSAGE,
        payload:""
    }
}

export const SignUpMessage = (data) =>{
    return {
        type: SIGN_UP_MESSAGE,
        payload : data
    }
}

export const SignUpMessageClear = () =>{
    return{
        type : SIGN_UP_MESSAGE_CLEAR
    }
}

const cacheHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }
    }
  }

// export const fetchAuthToken = () => {
//     return (dispatch)=>{
//         dispatch(fetchAuthTokenRequest());
//         // let token =  load('user_token');
//         let token = "MuQY1W27ITwqAPaXQdOZCo8P1DfYx5TAXvi4I2jLlvmzwCvUCXdpIqebs4t8_Uo0Puh3AcZveV967ttb0r0u2mxkRTqk7h7SZXR8N7kzpVFj18g0q0cNzfcVQL871THM-A6iDelZNV2AUwJ7Gb4PXASUduumwIct-QHtiSRhouA"


//         let localToken = localStorage.getItem("userToken")

//         if(localStorage.getItem("userToken") && !token){
//             dispatch(fetchAuthTokenSuccess(localToken))
//         }
//         else if (token){
//             localStorage.setItem("userToken", token);
//             dispatch(fetchAuthTokenSuccess(token));
//             remove('user_token');
//         }
//         else{
//             dispatch(fetchAuthTokenFailure(true))
//         }
//     }
// }

export const fetchAuthToken = (signin,cb,signUpFlag,recaptcha,toogleLang) => {
    return (dispatch)=>{
        dispatch({type: FETCH_AUTH_TOKEN_REQUEST})

        let APIURL = signUpFlag ? 'accounts/registrations/signup' : 'accounts/sessions/signin';
        let payload = signUpFlag ? signin : {signin}
        axios.post(`${REACT_APP_baseUrl}/${APIURL}`,payload,{
            headers:{
                "recaptcha-response": recaptcha?.current?.getValue()
            }
        })
        
        .then(response => {
            if (response.data?.data?.user.access_token){
                const userDetails = response.data?.data?.user
                if(typeof toogleLang === "function") toogleLang(userDetails?.language_code)
                let selectedCountry = {
                    countryName: userDetails?.country_name,
                    countryId:  userDetails?.country_id,
                    currencyId: userDetails?.currency_id,
                    countryCode: userDetails?.currency_code,
                    currencyCode: userDetails?.country_code,
                };
                let Languages
                if(store.getState()?.countries?.countries?.length > 0){
                    Languages = store.getState()?.countries?.countries?.filter(ite => ite?.currencies?.[0]?.id == selectedCountry.currencyId)
                }
                let payload = {
                    selectedCountry,
                    languages: Languages?.[0]?.languages,
                }
                localStorage.setItem("countryID", selectedCountry?.currencyId);
                localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry))
                dispatch(setLanguagesHandler(payload))
                dispatch(setCountryHandler(selectedCountry?.currencyId))
                recaptcha?.current?.reset()
                dispatch(fetchAuthTokenSuccess(response.data?.data?.user.access_token))
                localStorage.setItem("userToken", response.data?.data?.user.access_token);
                cookie.save("firstname", response.data?.data?.user.first_name)
                dispatch(fetchPointBalance(response.data?.data?.user.access_token));
                if(typeof cb === "function"){
                    cb()    
                }
                if(signUpFlag){
                    dispatch(SignUpMessage(payload.signup.email))
                }
            }
            else{
                recaptcha?.current?.reset()
                let errorMsg = response.data?.message || "Session Timeout"
                dispatch(fetchAuthTokenInvalid(errorMsg))
                signUpFlag && setTimeout(()=> dispatch(removeErrorMessage('')),2000)
            }
          
        })
        .catch((error)=>{
            recaptcha?.current?.reset()
            dispatch(fetchAuthTokenFailure(true))
        })
            
    }
}


export const checkTokenValidity = () => {
    
    const AccessToken = cookie.load("AccessToken") || null

    let token = localStorage.getItem("userToken") || AccessToken;

    if(localStorage.getItem("userToken") === null && AccessToken !== null){
        localStorage.setItem("userToken", AccessToken)
    }
    cookie.remove("AccessToken")

    let payload ={}
    return async (dispatch)=>{
        dispatch({type: FETCH_TOKEN_REQUEST})
        await axios.post(`${REACT_APP_baseUrl}/accounts/sessions/valid`,
        payload,
            {
                headers:{
                    "Authorization":`Bearer ${token}`
                }
            })
        .then(response => {

            if(response.data.data.valid){
               
               return dispatch({
                   type: VALID_TOKEN,
                   payload:{
                       authtoken: token
                   }
                    
                })
            }
            else{
                localStorage.removeItem("userToken")
                cookie.remove("firstname")
                return dispatch(fetchAuthTokenFailure("Session Timeout"))
            }
        })
        .catch((err) => {
            return dispatch(fetchAuthTokenFailure("Session Timeout"))
        })
            
    }
}

export const logout = (cb) => {
   
    let token = localStorage.getItem("userToken");
    // axios.post(`${REACT_APP_baseUrl}/accounts/sessions/valid`,
    return (dispatch) => {
        axios.delete(`${REACT_APP_baseUrl}/accounts/sessions/signout`,
            {
                headers:{
                    "X-Access-Token":token
                }
            })
            .then(response => {
                if(response.data.code === 200){ 
                    localStorage.removeItem("userToken");  
                    cookie.remove("firstname")
                    dispatch({
                       type: DELETE_TOKEN,               
                    })
                    // dispatch({
                    //     type: RESET_CLEAR
                    // })
                    if(typeof cb === 'function'){
                        cb()
                    }    
                }
            })
    } 
}

export const checkLoginFlow = (email, cb) =>{
    return (dispatch)=>{
        dispatch({type: FETCH_AUTH_TOKEN_REQUEST})

        axios.get(`${REACT_APP_baseUrl}/login-flow?email=${email}`)
        
        .then(response => {
            if (response?.data?.data){
                const Response = response?.data?.data
                cb(Response?.login_flow === "SSO" ? true : false)  
                localStorage.setItem("client_id",Response?.client_id)
                dispatch({
                    type: LOGIN_FLOW_SUCCESS,
                    payload: Response
                })  
            }
            
            else{
                dispatch(fetchAuthTokenInvalid(response?.data?.message || "Something went wrong!! Please try again!!"))
            }
          
        })
        .catch((error)=>{
            
            dispatch(fetchAuthTokenFailure(error?.sponse?.data?.message || "Something went wrong!! Please try again!!"))
        })
            
    }
}